jQuery(document).ready(function($) {

    if (('.main-slider').length > 0) {

        $('.main-slider').slick({
            dots: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 4500,
            speed: 800,
            fade: true,
            cssEase: 'linear',
            infinite: true,
            pauseOnHover: false
        });
    }

    if (('.service-slider').length > 0) {
        $('.service-slider').slick();
    }


    /**
     * Scroll to Anchor
     *
     */

    (function($) {
        $(function() {
            setTimeout(function() {
                if (location.hash) {
                    /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
                    window.scrollTo(0, 0);
                    target = location.hash.split('#');
                    smoothScrollTo($('#' + target[1]));
                }
            }, 1);

            // Select all links with hashes
            $('a[href*="#"]')
                // Remove links that don't actually link to anything
                .not('[href="#"]')
                .not('[href="#0"]')
                .click(function(event) {
                    // On-page links
                    if (
                        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                        &&
                        location.hostname == this.hostname
                    ) {
                        // Figure out element to scroll to
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        // Does a scroll target exist?
                        if (target.length) {
                            // Only prevent default if animation is actually gonna happen
                            event.preventDefault();
                            $('html, body').animate({
                                scrollTop: target.offset().top
                            }, 1000, function() {
                                // Callback after animation
                                // Must change focus!
                                var $target = $(target);
                                $target.focus();
                                if ($target.is(":focus")) { // Checking if the target was focused
                                    return false;
                                } else {
                                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                    $target.focus(); // Set focus again
                                }
                                ;
                            });
                        }
                    }
                });

            function smoothScrollTo(target) {
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                }
            }
        });
    })(jQuery);


    /**
     * sticky navigation
     */
    (function($) {
        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 2;
        var navbarHeight = $('#main-nav').outerHeight();

        $(window).scroll(function(event) {
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 400);

        function hasScrolled() {
            var st = $(this).scrollTop();

            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight) {
                // Scroll Down
                $('#main-nav').removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                if (st + $(window).height() < $(document).height()) {
                    $('#main-nav').removeClass('nav-up').addClass('nav-down');
                }
            }

            lastScrollTop = st;
        }
    })(jQuery);

    /**
     * mobile menu
     **/

    (function($) {
        $('#nav-icon').click(function() {
            $(this).toggleClass('open');
            $('#navbarCollapse').toggleClass('showMenu');
        });
    })(jQuery);
});
